import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { MyContext } from '../../context/context';
import DilogControl from '../../components/Dialog/DilogControl';
import ApplicationMenuBar from './MenuBar';
import { Spineer } from '../../../style/components/Spinner.styled';

const ApplicationDetails = () => {
  const {
    appError,
    candidateAppWebUrl,
    rejectDialog,
    setRejectDialog,
    approveDialog,
    setApproveDialog,
    applicationApprove,
    applicationReject,
    deleteDialog,
    setDeleteDialog,
    applicationDelete,
    deleteAppLoading,
    restoreDialog,
    setRestoreDialog,
    applicationRestore,
    archiveDialogControl,
    setArchiveDialogControllers,
    handleApplicationArchives,
    sendApplicationController,
    setSendApplicationController,
    sendAppToCandidate,
    resendAppToCandidate,
    reSendApplicationController,
    setReSendApplicationController,
    spineersLoader,
    setSpineersLoader,
    viewCandidateApp
  } = useContext(MyContext);
  const [loading, setLoading] = useState(true);

  const [appKey] = useSearchParams();
  const applicationId = appKey.get('applicationId');
  const candidateId = appKey.get('candidateId');
  const getAppKey = appKey.get('applicationKey');
  const redirectedFromEmail = appKey.get("redirectedFromEmail");

  useEffect(() => {
    viewCandidateApp({
      applicationId,
      candidateId,
      applicationStatus:"submitted",
      active:true,
    })
  },[redirectedFromEmail])
  const handleLoadIreame = () => setLoading(false);

  return (
    <>
      <section>
        <ApplicationMenuBar />
        {appError.isFailed ? (
          <Alert variant="filled" severity="error">
            {appError.errMessage}
          </Alert>
        ) : null}
        {loading && <div>
          {[...Array(5)].map((_, index) => (
            <div key={index}>
              <Skeleton variant="text" width={210} height={40} />
              <Skeleton variant="rectangular" width="100%" height={118} />
            </div>
          ))}
        </div>}
        <iframe
          src={`${candidateAppWebUrl}/application-admin-view?applicationKey=${getAppKey}`}
          style={{ height: '100vh', border: 'none' }}
          title="Candidate Application"
          onLoad={handleLoadIreame}
        />
      </section>
      <DilogControl
        open={rejectDialog}
        fullWidth
        maxWidth="sm"
        title="Reject Application"
      >
        <Typography paragraph>Write down rejection reason</Typography>
        <Formik
          initialValues={{ note: '' }}
          validationSchema={Yup.object({
            note: Yup.string('Enter Note').required('Note is required'),
          })}
          onSubmit={(value) => {
            setSpineersLoader(true);
            applicationReject({ ...value, applicationId, candidateId });
          }}
        >
          {(props) => {
            const {
              values, handleChange, touched, errors,
            } = props;
            return (
              <Form>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Note"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  error={touched.note && Boolean(errors.note)}
                  helperText={touched.note && errors.note}
                />
                <DialogActions>
                  <Button
                    type="button"
                    onClick={() => setRejectDialog(!rejectDialog)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    disabled={spineersLoader}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      Reject
                      {spineersLoader && <CircularProgress color="inherit" size={16} />}
                    </Box>
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DilogControl>
      <DilogControl
        open={approveDialog}
        fullWidth
        maxWidth="sm"
        title="Approve Application ?"
        dialogActions={(
          <>
            <Button
              type="button"
              onClick={() => setApproveDialog(!approveDialog)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              disableElevation
              onClick={() => {
                setSpineersLoader(true);
                applicationApprove({ applicationId, candidateId });
              }}
              disabled={spineersLoader}
            >
              <Box display="flex" alignItems="center" gap={1}>
                Approve
                {spineersLoader && <CircularProgress color="inherit" size={16} />}
              </Box>
            </Button>
          </>
        )}
      />

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(!deleteDialog)}
        PaperProps={{
          style: {
            borderRadius: 12,
            padding: '16px',
            maxWidth: '400px',
          },
        }}

      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this candidate application? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialog(!deleteDialog)}
            variant="contained"
            disabled={deleteAppLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => applicationDelete({ applicationId, candidateId })}
            color="error" disabled={deleteAppLoading}
            variant="contained"
          >
            {deleteAppLoading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <DilogControl
        open={restoreDialog}
        fullWidth
        maxWidth="sm"
        title="Would you like to Restore Application ?"
        dialogActions={(
          <>
            <Button
              type="button"
              onClick={() => setRestoreDialog(!restoreDialog)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              disableElevation
              onClick={() => applicationRestore({
                applicationId,
                candidateId,
                status: 'restored',
              })}
              disabled={spineersLoader}
            >
              Restore
              {spineersLoader && <CircularProgress color="inherit" size={16} />}

            </Button>
          </>
        )}
      />
      <DilogControl
        open={archiveDialogControl}
        fullWidth
        maxWidth="sm"
        title=" Would you like to archive this application? "
        dialogActions={(
          <>
            <Button
              type="button"
              onClick={() => setArchiveDialogControllers(!archiveDialogControl)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              disableElevation
              onClick={() => handleApplicationArchives({
                applicationId,
                candidateId,
                status: 'archived',
              })}
              disabled={spineersLoader}
            >
              <Box display="flex" alignItems="center" gap={1}>
                Archive
                {spineersLoader && <CircularProgress color="inherit" size={16} />}
              </Box>
            </Button>
          </>
        )}
      >
        If you proceed with archiving, please note that all actions and
        operations on it will be disabled, but you can restore it at any time.
        Are you sure you want to confirm this action?
      </DilogControl>

      <DilogControl
        open={sendApplicationController}
        fullWidth
        maxWidth="sm"
        title="Send Application? Candidate will be inform via email and will able to
        fill up the required details to start On Board proceess."
        dialogActions={(
          <>
            <Button
              type="button"
              onClick={() => setSendApplicationController(!sendApplicationController)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              disableElevation
              onClick={() => {
                setSpineersLoader(true);
                sendAppToCandidate({
                  applicationId,
                  candidateId,
                });
              }}
              disabled={spineersLoader}
            >
              <Box display="flex" alignItems="center" gap={1}>
                Send
                {spineersLoader && <CircularProgress color="inherit" size={16} />}
              </Box>
            </Button>
          </>
        )}
      />

      <DilogControl
        open={reSendApplicationController}
        fullWidth
        maxWidth="sm"
        title=" Would you like to resend the application?"
        dialogActions={(
          <>
            <Button
              type="button"
              onClick={() => setReSendApplicationController(!reSendApplicationController)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              disableElevation
              onClick={() => {
                setSpineersLoader(true);
                resendAppToCandidate({ applicationId, candidateId });
              }}
              disabled={spineersLoader}
            >
              <Box display="flex" alignItems="center" gap={1}>
                Re-Send
                {spineersLoader && <CircularProgress color="inherit" size={16} />}
              </Box>
            </Button>
          </>
        )}
      >
        A fresh email will be sent to the candidate, allowing them to fill in
        the required details to initiate or resume the onboarding process.
      </DilogControl>
    </>
  );
};

// ApplicationDetails.propTypes = {
//   values: PropTypes.shape({
//     note: PropTypes.string,
//   }),
//   handleChange: PropTypes.func,
//   touched: PropTypes.bool,
//   errors: PropTypes.bool,
// };
// ApplicationDetails.defaultProps = {
//   values: {
//     note: '',
//   },
//   handleChange: () => {},
//   touched: false,
//   errors: false,
// };

export default ApplicationDetails;
