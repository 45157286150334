import { startCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Typography,
  FormLabel,
} from '@mui/material';
import DilogControl from '../../components/Dialog/DilogControl';
import {
  CardContainer,
  CardFooter,
  CardHead,
  CardWrapper,
  CreatedAt,
} from '../../../style/components/card.styled';
import MenuBar from './MenuBar';
import AvatarFnmLnm from '../../../func/AvatarContent';
import { MyContext } from '../../context/context';
import UserForm from './UserForm';
import FlexedBox from '../../components/Other/FlexedBox';
import UserListSkeleton from './statistics/UserListSkeleton';
import NoDataSVGFile from '../../../img/svg/NoDataSVGFile';

const UserList = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    getUserListFunc,
    userLists,
    render,
    userDelDialogState,
    handleUserDeleDialog,
    handleUserDel,
    userIds,
    setUserId,
    setDialogControl,
    loading,
  } = useContext(MyContext);
  const [resolutionController, setResolutionController] = useState(
    window.innerWidth,
  );

  useEffect(() => {
    getUserListFunc();
    const resolutionRecorder = () => {
      setResolutionController(window.innerWidth);
    };

    /* Adding Event Listners for Scroll and Re-size */
    /* for Scroll-to-Top and Resolution Controll */
    window.addEventListener('resize', resolutionRecorder);

    /* Dumping/Cleaning Up all task at end of useEffect */
    /* Execution to avoid memory leak and Other issues */
    return () => {
      window.removeEventListener('resize', resolutionRecorder);
    };
  }, [render]);
  const [dialogData, setDialogData] = useState();
  return (
    <>
      <section>
        <MenuBar />
        <CardWrapper>
          {!loading ? (
            userLists.map((item) => {
              const {
                userId,
                createdAt,
                role,
                firstname,
                lastname,
                active,
                creator,
              } = item;
              return (
                <React.Fragment key={userId}>
                  <CardContainer leftborder="true" active={active.toString()}>
                    {/* <CreatedAt></CreatedAt> */}
                    <div className="flex-space-between">
                      <CardHead
                        avatar={(
                          <Avatar>
                            {AvatarFnmLnm({ fnm: firstname, lnm: lastname })}
                          </Avatar>
                        )}
                        title={<>{startCase(`${firstname}  ${lastname}`)}</>}
                        subheader={role}
                        // onClick={() =>
                        //   handleUserUpdate({
                        //     userId,
                        //     firstname,
                        //     lastname,
                        //     role,
                        //     email,
                        //   })
                        // }
                        onClick={() => {
                          // eslint-disable-next-line no-param-reassign
                          item.passwordController = false;
                          setDialogControl(1);
                          setDialogData(item);
                          setIsDialogOpen(!isDialogOpen);
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setUserId(userId);
                          handleUserDeleDialog();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <CardFooter>
                      <div className="grid-column-50-2">
                        <CreatedAt>
                          <Person4OutlinedIcon />
                          <Typography className="overflow-hidden-text">
                            {creator}
                          </Typography>
                        </CreatedAt>
                        <CreatedAt>
                          <div className="flexing-content-end">
                            <AccessTimeIcon />
                            <Typography>{createdAt}</Typography>
                          </div>
                        </CreatedAt>
                      </div>
                      {/* {role.toLowerCase() === 'hr' ? (
                    <Button
                      disableElevation
                      onClick={() => handleHrApp(userId)}
                    >
                      View applications
                    </Button>
                  ) : null} */}
                    </CardFooter>
                  </CardContainer>
                </React.Fragment>
              );
            })
          ) : (
            <UserListSkeleton resolutionController={resolutionController} />
          )}
        </CardWrapper>
        {!loading && userLists.length <= 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',

            }}
          >
            <FormLabel
              sx={{
                color: 'error.main',
                fontWeight: '400',
                fontSize: '24px',
              }}
            >
              No Associated Data
            </FormLabel>
            <span><NoDataSVGFile /></span>
          </div>
        )}
      </section>
      <DilogControl
        open={userDelDialogState}
        title="Delete the user?"
        maxWidth="sm"
        dialogActions={(
          <>
            <Button onClick={handleUserDeleDialog}>Disagree</Button>
            <Button
              onClick={() => {
                handleUserDel(userIds);
              }}
            >
              Agree
            </Button>
          </>
        )}
      >
        Please be aware that if you choose to delete this user, both the users
        account and any associated on-board applications will be irrevocably and
        permanently removed from our system. Are you certain that you wish to
        proceed with this deletion?
      </DilogControl>
      <DilogControl
        open={isDialogOpen}
        fullWidth
        maxWidth="md"
        title={(
          <FlexedBox
            direction="row"
            justifyContent="space-between"
            alignContent="center"
          >
            <span>Update {dialogData && dialogData?.role}</span>
            <IconButton
              sx={{ padding: '0px' }}
              onClick={() => setIsDialogOpen(!isDialogOpen)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </FlexedBox>
        )}
      >
        <UserForm
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          data={dialogData}
        />
      </DilogControl>
      {/* {dialogMode && ( */}
      {/* <UserForm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        dialogMode={dialogControl}
        dialogData={dialogData}
        loading={loading}
      /> */}
      {/* )} */}
    </>
  );
};

export default UserList;
