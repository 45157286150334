import { enqueueSnackbar } from 'notistack';
import {
  createContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  debounce, isEmpty, isEqual, pick,
} from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  appKeyApi,
  appListApi,
  createUserApi,
  deleteAppApi,
  isLoggedinApi,
  resendAppToCandidateApi,
  restoreAppApi,
  roleChoiceApi,
  sendAppToCandidateApi,
  updateAppStatusApi,
  userListApi,
  loginApi,
  logoutApi,
  userDeleteApi,
  updateApplicationDetails,
  userGetApi,
  updateUserApi,
  adminProfileAPI,
} from '../../apis/apis';
import createUserFormInitialValues from '../pages/user/statistics/createUserFormInitialValues';
import showSnackbar from '../components/Message/showSnackbar';

const MyContext = createContext();
const candidateFrontUrl = process.env.REACT_APP_CANDIDATE_FRONT_URL;

const MyProvider = ({ children }) => {
  const candidateApplicationWebUrl = candidateFrontUrl;
  // const candidateApplicationWebUrl = 'http://dev.onboarding.ctserver.space';
  // const candidateApplicationWebUrl = 'http://sit.onboarding.ctserver.space';
  /** ──────────────────────────────────────────────
   * APIs errors handling state
   */

  /** Check is server is up or not */
  // const [isServerUp] = useState(false);

  /* 1) useNavigate() hook for navigate page */
  const navigate = useNavigate();
  const locationForIsLoggedIn = useLocation();
  const [render, setRender] = useState(false);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [currentUserRole,setCurrentUserRole] = useState("");
  const [roleChoicesOptions, setRoleChoicesOptions] = useState([
    {
      value: 'loading',
      label: 'Loading...',
    },
  ]);

  const [globalError, setGlobalError] = useState({
    isError: false,
    status: 404,
    title: 'Page not found',
    code: 'connection',
    message: 'Lost connection',
  });

  /** Handle failure response  */
  const handleFailureResponse = (props) => {
    const {
      status, location, code, message,
    } = props;

    if (isEmpty(status, 0)) navigate(location);
    if (isEqual(status, 403)) navigate(location);

    setGlobalError((preVal) => ({
      ...preVal,
      isError: true,
      status,
      code,
      message,
    }));
  };

  /** @ General APIs list
   * 1) role choices api
   * *) all apis call in single func
   */

  const getRoleSelectOptionsHelper = async (args) => {
    const roles = await roleChoiceApi(args);
    setRoleChoicesOptions(roles.data);
  };

  /** Old function remove it */
  // const loadRoleChoices = async () => {
  //   const roles = await roleChoiceApi();

  //   const { success } = roles;
  //   if (!success) {
  //     handleFailureResponse(
  //       pick(roles, ['status', 'location', 'code', 'message']),
  //     );
  //   }
  //   setRoleChoicesOptions(roles.data);
  // };

  /**
   * @ Hooks
   * 1) React Router-dom
   * - useNavigate()
   * - useLocation()
   */

  /* 2) useLocation() hooke for check current page location */
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;

  /**
   * @ User Login & Logout
   * 1) - Login form initial values
   * 2) - Login button handler
   * 3) - isLogin handler
   * 4) - Logout button hadler
   */

  /* 1) - Login form init val */
  const loginFormInitialValues = {
    email: '',
    password: '',
  };

  /* 2) - Login Button handler */
  const handleLoginButtonClick = async (props) => {
    const loginApiRes = await loginApi(props);
    const { success, message } = loginApiRes;
    if (success) {
      showSnackbar(message, { variant: 'success' });
      setIsAuthorised(loginApiRes.authorised);
      navigate(loginApiRes.location);
    } else {
      showSnackbar(message, { variant: 'error' });
    }
  };

  /* 3) - isLogin api handler */
  const checkUserIsLoggedInDebounce = async () => {
    const isLoggedin = await isLoggedinApi();
    const {
      success, location, status, message,
    } = isLoggedin;
    setCurrentUserRole(isLoggedin.role);
    if (!success) {
      handleFailureResponse(
        pick(isLoggedin, ['status', 'location', 'code', 'message']),
      );
    }
    if (!success && status === 500) {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 });
      return navigate(location);
    }
    if (status === 403) {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 });
      return navigate(location);
    }
    return setIsAuthorised(isLoggedin.authorised);
  };

  const setIsLoggedInDebounced = useRef(
    debounce(checkUserIsLoggedInDebounce, 300),
  );

  const checkUserIsLoggedIn = async () => {
    if (locationForIsLoggedIn.pathname.includes('/application')) {
      setIsLoggedInDebounced.current();
    } else {
      checkUserIsLoggedInDebounce();
    }
  };

  /* 4) Logout button handler */
  const handleLogoutButtonClick = async () => {
    const logoutApiRes = await logoutApi();
    const { success, message } = logoutApiRes;
    if (success) {
      navigate(logoutApiRes.location);
    } else {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
    }
  };

  /**
   * @ Application Management
   * 1) Handle create applicaiton button click
   * 2) Create Application form initial values
   * 3) Submit onBoard Application
   * 4) Edit Submitted onBoard Application
   * 5) Send onBoard Application to candidate via email
   * 6) add onBoard Application to Draft
   * 7) single application view
   * 8) reject application handles
   */

  const [hrChoiceState, setHrChoiceState] = useState([
    {
      displayName: 'Loading...',
      id: 'null',
    },
  ]);

  /* 1) handle create application */
  const handleCreateApplicationButtonClick = () => {
    navigate('/create-application');
  };

  /* 2) create app form init val */
  const createApplicationFormInitialValues = {
    firstname: '',
    lastname: '',
    email: '',
    contact_no: '',
    password: '',
    confirm_password: '',
  };

  /* View application */
  // const viewCandidateOnBoardApplication = async (props) => {
  //   const { applicationId, candidateId } = props;
  //   const getAppKeyApi = await appKeyApi(props);
  //   const { isAppActive, applicationStatus, applicationKey } =
  //     getAppKeyApi.data;
  //   navigate(
  //     `/application-details?applicationId=${applicationId}&candidateId=${candidateId}&applicationKey=${applicationKey}&applicationStatus=${isAppActive ? applicationStatus : 'archived'
  //     }`,
  //   );
  // };
  const viewCandidateOnBoardApplication = async (props) => {
    const { applicationId, candidateId } = props;
    
    try {
      const getAppKeyApi = await appKeyApi(props);
  
      // Check if the API response is valid
      if (getAppKeyApi && getAppKeyApi.data) {
        const { isAppActive, applicationStatus, applicationKey } = getAppKeyApi.data;
  
        // Ensure values are available before proceeding
        const status = isAppActive ? applicationStatus : 'archived';
  
        navigate(`/application-details?applicationId=${applicationId}&candidateId=${candidateId}&applicationKey=${applicationKey}&applicationStatus=${status}`);
      } else {
        // Handle the case where the API response does not contain data
        console.error('API response does not contain valid data');
        // You may show a user-friendly error message or handle the case accordingly
      }
    } catch (error) {
      // Handle network or API call errors
      console.error('Error fetching application data:', error);
      // Optionally, show an error message to the user
    }
  };
  

  /* Application filter state */
  const [applicationFilterState, setApplicationFilterState] = useState({
    applicationTag: '',
    applicationCandidates: [],
    applicationStatus: '',
    createdBy: '',
  });
  const [applicationFilterStringState, setApplicationFilterStringState] =
    useState('');

  /* Application error */
  const [applicationError, setApplicationError] = useState({
    isFailed: false,
    errMessage: '',
    statusCode: 0,
  });

  /* Application filter handler */
  const handleApplicationFilter = (e) => {
    const { name, value } = e.target;
    setApplicationFilterState((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setRender(!render);
  };

  /* Application Dialog states handling */
  const [approveDialogBox, setApproveDialogBox] = useState(false);
  const [rejectDialogBox, setRejectDialogBox] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const [restoreDialogBox, setRestoreDialogBox] = useState(false);
  const [archiveDialogController, setArchiveDialogController] = useState(false);
  const [sendApplicationDialogController, setSendApplicationDialogController] =
    useState(false);
  const [
    reSendApplicationDialogController,
    setReSendApplicationDialogController,
  ] = useState(false);
  /* Application status function to manage api calls and application error */
  const [spineerLoader, setSpineerLoader] = useState(false);
  const handleApplicationStatus = async (props) => {
    const updatedApplicationData = await updateAppStatusApi(props);
    const { success, message } = updatedApplicationData;

    // if (!success) {
    //   return { success, message };
    // }

    return { success, message };

    // return navigate('/application');
  };

  /* handle application approve button click */
  const handleApplicationApprove = async (props) => {
    Object.assign(props, { status: 'approved' });
    const { success, message } = await handleApplicationStatus(props);
    if (success) {
      setSpineerLoader(false);
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
      return navigate('/application');
    }
    if (!success) {
      setSpineerLoader(false);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setApproveDialogBox(false);
    }
    return true;
  };

  /* handle application reject button click */
  const handleApplicationReject = async (props) => {
    Object.assign(props, { status: 'rejected' });
    const { success, message } = await handleApplicationStatus(props);
    if (success) {
      setSpineerLoader(false);
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
      return navigate('/application');
    }
    if (!success) {
      setSpineerLoader(false);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setRejectDialogBox(false);
    }
    return true;
  };

  /* handle application draft button click */
  const handleApplicationDraft = async (props) => {
    Object.assign(props, { status: 'draft' });
    const { success, message } = await handleApplicationStatus(props);
    if (success) {
      return navigate('/application');
    }
    if (!success) {
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
    }
    return true;
  };

  /**
   * Function to handle the deletion of an application.
   * 
   * Previous Developer's @Milan :
   * - Function initially designed to handle deletion and provide user feedback.
   * - Integrated success and failure states with snackbar notifications.
   * - Navigates back to the application list on successful deletion.
   * 
   * Current Developer's @Hitesh:
   * - Added error handling for unexpected API failures (e.g., network errors).
   * - Used a `finally` block to ensure the loading state resets regardless of the outcome.
   * - Improved error messaging for fallback scenarios.
   * - Ensured consistency in flow control by removing redundant `return true`.
   */
  const [deleteAppLoading, setDeleteAppLoading] = useState(false);
  const handleApplicationDelete = async (props) => {
    try {
      setDeleteAppLoading(true);

      const deletedApp = await deleteAppApi(props);
      const { success, message } = deletedApp;

      if (success) {
        enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
        navigate('/application');
      } else {
        setApplicationError({
          isFailed: true,
          errMessage: message,
        });
        setDeleteDialogBox(false);
      }
    } catch (error) {
      setApplicationError({
        isFailed: true,
        errMessage: 'An unexpected error occurred. Please try again later.',
      });
      setDeleteDialogBox(false);
    } finally {
      setDeleteAppLoading(false);
    }
  };

  /* handle application restore button click */
  const handleApplicationRestore = async (props) => {
    setSpineerLoader(true);
    const restoredAppRes = await restoreAppApi(props);
    setSpineerLoader(false);
    const { success, message } = restoredAppRes;
    if (success) {
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
      return navigate('/application');
    }
    if (!success) {
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setRestoreDialogBox(false);
    }
    return true;
  };

  const handleApplicationArchive = async (props) => {
    setSpineerLoader(true);
    const restoredAppRes = await handleApplicationStatus(props);
    setSpineerLoader(false);
    const { success, message } = restoredAppRes;
    if (success) {
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
      return navigate('/application');
    }
    if (!success) {
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setArchiveDialogController(false);
    }
    return true;
  };

  /* handle application send app button click */
  const handleSendApplicationToCandidate = async (props) => {
    const { success, message } = await sendAppToCandidateApi(props);
    if (success) {
      setSpineerLoader(false);
      showSnackbar(message, { variant: 'success' });
      return navigate('/application');
    }
    if (!success) {
      setSpineerLoader(false);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setSendApplicationDialogController(!sendApplicationDialogController);
      showSnackbar(message, { variant: 'error' });
    }
    return true;
  };

  /* handle application re-send app button click */
  const handleResendApplicationToCandidate = async (props) => {
    const { success, message } = await resendAppToCandidateApi(props);
    if (success) {
      setSpineerLoader(false);
      enqueueSnackbar(message, { variant: 'success', autoHideDuration: 5000 });
      return navigate('/application');
    }
    if (!success) {
      setSpineerLoader(false);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
    }
    setReSendApplicationDialogController(!reSendApplicationDialogController);
    return true;
  };

  /**
   * @ User Management
   * 1) Handle create user button click
   */

  /* 1) Handle create user button click */

  const [userFormInitialValuesState, setUserFormInitialValuesState] = useState(
    createUserFormInitialValues,
  );

  const [userFromDialogBox, setUserFromDialogBox] = useState({
    title: 'Create',
    active: false,
  });

  const handleUserFormDialogBox = () => {
    setUserFromDialogBox((prevProps) => ({
      ...prevProps,
      title: 'create',
      active: !userFromDialogBox.active,
    }));
  };

  const handleUserUpdateClick = (props) => {
    setUserFromDialogBox((prevProps) => ({
      ...prevProps,
      title: 'update',
      active: !userFromDialogBox.active,
    }));
    setUserFormInitialValuesState((prevProps) => ({
      ...prevProps,
      userId: props.userId,
      firstname: props.firstname,
      lastname: props.lastname,
      email: props.email,
      role: props.role,
      password: 'ps',
    }));
  };

  const handleUserFormSubmit = async (props) => {
    const userCreateResponse = await createUserApi(props);
    return userCreateResponse;
  };

  const handleCreateUserButtonClick = () => {
    navigate('/create-user');
  };

  const [loadingData, setLoadingData] = useState(false);
  const [applicationsDataLists, setApplicationsDataLists] = useState([]);
  const [usersDataLists, setUsersDataLists] = useState([]);

  const handleDebouncing = async (props) => {
    const applist = await appListApi(props);
    const { data, success, message } = applist;
    setLoadingData(true);
    if (success) {
      setLoadingData(false);
      setApplicationsDataLists(data);
    }
    if (!success) {
      setApplicationsDataLists([]);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setTimeout(() => {
        setApplicationError(() => ({
          isFailed: false,
        }));
      }, 3000);
      setLoadingData(false);
      // setRender(!render);
    }
  };

  const setServicesValueDebounced = useRef(debounce(handleDebouncing, 500));

  const getApplicationListsFunc = async (props) => {
    setServicesValueDebounced.current(props);
  };

  const [dialogControlMode, setDialogControlMode] = useState(-1);

  const getSingleUser = async (id) => {
    const userData = await userGetApi(id);
    return userData;
  };

  const updateSingleUser = async (data) => {
    const userDataUpdateResponse = await updateUserApi(data);
    return userDataUpdateResponse;
  };

  const handleUserDebouncing = async (props) => {
    setLoadingData(true);
    // setTimeout(async () => {
    const userList = await userListApi(props);
    const { data, success, message } = userList;
    if (success) {
      setLoadingData(false);
      setUsersDataLists(data);
    }
    if (!success) {
      setUsersDataLists([]);
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
      setTimeout(() => {
        setApplicationError(() => ({
          isFailed: false,
        }));
      }, 3000);
      setLoadingData(false);
    }
    // }, 2000);
  };

  const userListDebounce = useRef(debounce(handleUserDebouncing, 500));

  const getUserListsFunc = async (props) => {
    userListDebounce.current(props);
  };

  const [userDeleteDialogState, setUserDeleteDialogState] = useState(false);
  const [userID, setUserID] = useState();

  const handleUserDeleteDialog = async () => setUserDeleteDialogState(!userDeleteDialogState);

  const handleUserDelete = async (props) => {
    await userDeleteApi(props);
    setUserDeleteDialogState(!userDeleteDialogState);
    setRender(!render);
  };

  const handleHrApplications = async (userId) => {
    setApplicationFilterStringState(`createdBy=${userId}`);
    navigate('/application');
  };

  const handleApplicationUpdateDetails = async (props) => {
    const updatedApplicationDetails = await updateApplicationDetails(props);
    const { success, message } = updatedApplicationDetails;
    if (!success) {
      setApplicationError(() => ({
        isFailed: true,
        errMessage: message,
      }));
    }
  };

  const handleGetAdminProfile = async () => {
    const data = await adminProfileAPI();
    return data;
  };

  useEffect(() => {
    setLoadingData(true);
    const pathToIgnore = ['/login', '/add-new-password', '/resest-password'];
    if (!pathToIgnore.includes(locationForIsLoggedIn.pathname)) {
      checkUserIsLoggedIn();
    }
    // loadRoleChoices();
  }, [userFromDialogBox, render]);

  const val = useMemo(
    () => ({
      /** api error state */
      /** Application */
      appFilterState: applicationFilterState,
      setAppFilterState: setApplicationFilterState,
      appFilterStrState: applicationFilterStringState,
      hrChoice: hrChoiceState,
      setHrChoice: setHrChoiceState,
      handleAppFilter: handleApplicationFilter,
      /**  */

      /** User */
      userFormInitVal: userFormInitialValuesState,
      userDelDialogState: userDeleteDialogState,
      userIds: userID,
      setUserId: setUserID,
      handleUserDeleDialog: handleUserDeleteDialog,
      handleUserDel: handleUserDelete,
      handleUserUpdate: handleUserUpdateClick,
      handleAppDetailsUpdate: handleApplicationUpdateDetails,
      handleHrApp: handleHrApplications,
      getRoleSelectOptions: getRoleSelectOptionsHelper,
      render,
      setRender,
      candidateAppWebUrl: candidateApplicationWebUrl,
      error: globalError,
      appError: applicationError,
      setAppError: setApplicationError,
      authorised: isAuthorised,
      roleChoices: roleChoicesOptions,
      loading: loadingData,
      setLoading: setLoadingData,
      location: currentLocation,
      path: currentPath,
      loginInitVal: loginFormInitialValues,
      handleLogin: handleLoginButtonClick,
      handleLogout: handleLogoutButtonClick,
      appInitVal: createApplicationFormInitialValues,
      viewCandidateApp: viewCandidateOnBoardApplication,
      createApp: handleCreateApplicationButtonClick,
      createUser: handleCreateUserButtonClick,
      getUser: getSingleUser,
      updateUser: updateSingleUser,
      dialogControl: dialogControlMode,
      setDialogControl: setDialogControlMode,
      handleAppStatus: handleApplicationStatus,
      rejectDialog: rejectDialogBox,
      setRejectDialog: setRejectDialogBox,
      approveDialog: approveDialogBox,
      setApproveDialog: setApproveDialogBox,
      deleteDialog: deleteDialogBox,
      setDeleteDialog: setDeleteDialogBox,
      restoreDialog: restoreDialogBox,
      setRestoreDialog: setRestoreDialogBox,
      archiveDialogControl: archiveDialogController,
      setArchiveDialogControllers: setArchiveDialogController,
      setSpineersLoader: setSpineerLoader,
      spineersLoader: spineerLoader,
      handleApplicationArchives: handleApplicationArchive,
      applicationRestore: handleApplicationRestore,
      getApplicationListFunc: getApplicationListsFunc,
      applicationLists: applicationsDataLists,
      getUserListFunc: getUserListsFunc,
      userLists: usersDataLists,
      applicationApprove: handleApplicationApprove,
      applicationReject: handleApplicationReject,
      applicationDelete: handleApplicationDelete,
      deleteAppLoading: deleteAppLoading,
      applicationDraft: handleApplicationDraft,
      sendAppToCandidate: handleSendApplicationToCandidate,
      sendApplicationController: sendApplicationDialogController,
      setSendApplicationController: setSendApplicationDialogController,
      reSendApplicationController: reSendApplicationDialogController,
      setReSendApplicationController: setReSendApplicationDialogController,
      resendAppToCandidate: handleResendApplicationToCandidate,
      userFromDialogActivation: userFromDialogBox.active,
      userFromDialogTitle: userFromDialogBox.title,
      toggleUserFormDialogBox: handleUserFormDialogBox,
      handleUserForm: handleUserFormSubmit,
      getAdminProfile: handleGetAdminProfile,
      currentUserRole:currentUserRole,
      checkUserIsLoggedInDebounce:checkUserIsLoggedInDebounce,
    }),
    [viewCandidateOnBoardApplication],
  );

  return <MyContext.Provider value={val}>{children}</MyContext.Provider>;
};

MyProvider.propTypes = {
  children: PropTypes.element.isRequired,
  applicationId: PropTypes.string,
  candidateId: PropTypes.string,
  status: PropTypes.number,
  location: PropTypes.string,
  code: PropTypes.string,
  message: PropTypes.string,
  userId: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  // password: PropTypes.string,
};

// MyProvider.defaultProps = {
//   applicationId: '',
//   candidateId: '',
//   status: 0,
//   location: '',
//   code: '',
//   message: '',
//   userId: '',
//   firstname: '',
//   lastname: '',
//   email: '',
//   role: '',
//   // password: '',
// };

export { MyContext, MyProvider };
