import { isEqual } from 'lodash';

const apiResData = (props) => {
  const resData = {
    success: props.data.success,
    status: props.status,
    message: props.data.message,
    location: props.headers.location || null,
    data: props.data.data || null,
    total: props.data.total || null,
    error: props.data.error || null,
    authorised: props.data.authorised,
    role:props.data.role || null,
  };
  return resData;
};

const apiErrData = (props) => {
  const { code, response } = props;

  if (isEqual(code, 'ERR_NETWORK')) {
    /** @ERR_NETWORK */
    const data = {
      code,
      success: response.data || false,
      status: response.status,
      message: props?.message,
      location: '/error',
    };
    return data;
  }
  if (isEqual(code, 'ECONNABORTED')) {
    const data = {
      code,
      success: false,
      status: 500,
      message: props.message,
      location: '/error',
    };

    return data;
  }

  const data = {
    errCode: code,
    success:
      response.data.success === undefined ? false : response.data.success,
    status: response.status,
    message:
      response.data.message
      || response.data.error.message
      || props.response.statusText,
    location: response.headers.location || null,
  };

  return data;
};

export { apiResData, apiErrData };
